import React from 'react'
import { Link, graphql} from 'gatsby';
import Layout from '../components/layout'

import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'

const Person = ({ data, location, pageContext }) => {
  return (
    <Layout>

      <SEO
        pagetitle={`偉人一覧`}
        pagedesc={`偉人一覧`}
        pagepath={location.pathname}
      />


      <div className='l-people'>
        <div className="l-wrap">
          <h1 className="m-heading">偉人一覧</h1>
          <ul className="l-people__list">
            {data.cate.edges.map(({ node }) => (
              <li className="l-people__item" key={node.id}>
                <Link to={`/person/${node.slug}`}>
                  <div className="l-people__wrap">
                    <figure className="l-people__img">
                      <GatsbyImage
                        image={node.selfie.gatsbyImageData}
                        description={node.selfie.description}
                      />
                    </figure>
                    <div className="l-people__texts">
                      <h3 className="l-people__hdg">{`${node.name}`}</h3>
                      <p className="l-people__num">{node.length}</p>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>


    </Layout>

  )
}

export const query = graphql`
  query {
    cate: allContentfulCategory(sort: {fields: slug}) {
      edges {
        node {
          slug
          sort
          name
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
            id
            title
          }
        }
      }
    }
  }
`

export default Person;